import React from 'react';

import "./style.css";

class Order extends React.Component {
constructor(props) {
  super(props);

  this.state = {
    loading: false,
    }
  }

componentDidMount() {
  window.scrollTo(0, 0);
}

  render() {
 
  return (
      <div style={{padding: "10px"}}>
        <h1>We kijken nog even alles na!</h1>
        <br/>
        <p>Als alles goed is verlopen, en de betaling is goed ontvangen, zal je een email ontvangen. (Bekijk zeker de SPAM folder!)</p>
        <p>Indien de betaling niet is doorgegaan, zal er ook geen email worden uitgestuurd en ben je niet ingeschreven.</p>
        <br/>
        <p>Heb je toch nog een vraag, 1 (email)adres, baptistfeesten@vocschriek.be</p>
        <br/>
        <p>Tot binnenkort!</p>
      </div>

  );
    }
  }

export default Order
