import app from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyBt52CBM1wLfhSXzrX_jE3tgZSfpwoxXsI",
  authDomain: "baptistfeesten.firebaseapp.com",
  databaseURL: "https://baptistfeesten-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "baptistfeesten",
  storageBucket: "baptistfeesten.appspot.com",
  messagingSenderId: "903118710770",
  appId: "1:903118710770:web:8494b6e1c976e600cdad6b"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.db = app.database();
    this.serverValue = app.database.ServerValue;
  }

    dartsInschrijvingen = () => this.db.ref(`darts`);

    dartsInschrijvingenItem = uid => this.db.ref(`darts/${uid}`);

    tornooiInschrijvingen = () => this.db.ref(`tornooi`);

    tornooiInschrijvingenItem = uid => this.db.ref(`tornooi/${uid}`);

    zaterdagReserveringen = uid => this.db.ref(`tafels/reserveringen/zaterdag/${uid}`);

    zondagReserveringen = uid => this.db.ref(`tafels/reserveringen/zondag/${uid}`);

    zaterdagDB = uid => this.db.ref(`tafels/zaterdag/${uid}`);

    zondagDB = uid => this.db.ref(`tafels/zondag/${uid}`);

    tafel = uid => this.db.ref(`tafels/${uid}`);

    tafels = () => this.db.ref('tafels');
}



export default Firebase;
