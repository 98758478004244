export const HOME = "/";
export const BARBAPTIST = "/barbaptist";
export const SPRINGKASTELEN = "/springkastelenfestival";
export const DARTS = "/darts";
export const RESTAURANT = "/chezbaptist";
export const MENU = "/chezbaptist/menu";
export const TORNOOI = "/volleybaltornooi";
export const INITIATIES = "/initiatievolleybal"
export const CORONA = "/corona"

export const ORDERS_DETAILS = '/inschrijving_controle';