import React from 'react';
import './style.css';
import affiche from './affiche.png';
import groep1 from './band1.jpg';
import groep2 from './band2.jpg';
import groep3 from './band3.jpg';
import groep4 from './gertv.jpg';
import groep5 from './sakkeenlauw.png';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Header from '../header/Header';

import bier from "../info/baptistfeesten 2023_assets-12.png"

class Barbaptist extends React.Component {

  componentDidMount() {
      window.scrollTo(0, 0);
    }

  render() {

    const band1 = {
      naam: "The Mind",
      tekst: "De jonge rockgroep The Mind ontstond eind 2022, toen vier muzikanten uit de Berlaarse schoolband SubSonic hun krachten bundelden. De covers maakten al snel plaats voor een flink aantal eigen nummers, waarin invloeden van Bruce Springsteen, Coldplay en Green Day doorklinken. Rob Moens (zang/gitaar), Otis Beernaert (zang/bas), Sebastiaan Vincent (keys) en Rube Dons (drums) brengen live een perfecte balans tussen power en melodie, van indie tot meer poppy. In januari brachten ze in eigen beheer hun eerste single ‘Pink ‘n Blue’ uit via Spotify.",
      website: "",
      facebook: "https://www.facebook.com/people/The-Mind/100089712245841/",
      instagram: "https://www.instagram.com/themind.official",
      youtube: "",
      vi: "https://vi.be/platform/TheMind",
      uur: "20u00"
    }

    const band2 = {
      naam: "Ave Noenkel",
      tekst: `Ave Noenkel speelt muziek zoals het bedoeld werd: luid en live!  Het viertal zet zware gitaren terug op de muzikale kaart.  Van snoeiharde rock covers tot de revival van de "slow", Ave Noenkel doet het allemaal.  Met hun anthem "Queen of the Keitel" heeft de band de verovering van ons land voor eens en voor altijd ingezet. Schriek, bereid u voor op een avond vol zingen, dansen, roepen en tieren onder leiding van uw favoriete Noenkels!`,
      website: "",
      facebook: "https://www.facebook.com/profile.php?id=100090711665288",
      instagram: "",
      youtube: "",
      vi: "",
      uur: "22u00"
    }

    const band3 = {
      naam: "De Schlagerij",
      tekst: "Verzorgt De Schlagerij de muzikale gastronomie op jouw evenement? Verwacht niets minder dan absolute muzikale fijnkost op Vlaamsche wijze. Vergeet Irish beef, Japanse Wagyu of Amerikaanse ribeye. Niets zo smakelijk als ons eigen rasecht Witblauw. Speciaal voor u hebben wij het kruim van de Nederlandstalige muziek door de gehaktmolen gehaald en rijkelijk overgoten met een stevige saus van rock-jump-shout-‘n-roll. Wij brengen een muzikaal spektakel om duimen en vingers bij af te likken waarbij geen enkele hansworst kan blijven stilstaan. U neemt geen genoegen met flauw en uitgedroogd kippenwit van de weight-watchers? Dan is De Schlagerij zeker spek naar uw bek!",
      website: "https://www.deschlagerij.be/",
      facebook: "https://www.facebook.com/schlagerij",
      instagram: "https://www.instagram.com/deschlagerij/",
      youtube: "",
      vi: "",
      uur: "24u00"
    }

    const band4 = {
      naam: "DJ Gert V",
      tekst: "DJ Gert V rijgt voor u de beste zomerhits van de laatste jaren aan elkaar zodat we samen gezellig de nacht kunnen afsluiten op ons geliefde Schriekse kerkplein.",
      website: "https://djgertv.be/",
      facebook: "https://www.facebook.com/GertVanAsselberghs/?locale=nl_NL",
      instagram: "https://www.instagram.com/djgertv/",
      youtube: "",
      vi: "",
      uur: "01u00"
    }

    const band5 = {
      naam: "Sakke Saucisse & Lauwe Biefstuk",
      tekst: "Laat het thuisfront maar weten dat het feestje niet direct gedaan zal zijn. Dit plaatselijk dj-duo weet met hun plaatjes menig vlees te doen bewegen.",
      website: "",
      facebook: "https://www.facebook.com/SakkeSaucisseLauweBiefstuk",
      instagram: "",
      youtube: "",
      vi: "",
      uur: ""
    }

    var groep1Img = "url(" + groep1 + ")"
    var groep2Img = "url(" + groep2 + ")"
    var groep3Img = "url(" + groep3 + ")"
    var groep4Img = "url(" + groep4 + ")"
    var groep5Img = "url(" + groep5 + ")"



    return (
    <div className="page">
      <Header id="barbaptist"/>
      <div className="activiteitpagina">
        <div className="activiteitpagina-header">
          <Jumbotron fluid>
            <Container>
              <h1>Bar Baptist</h1>
              <p>
                Een danske placeren in het gezelschap van heerlijke bieren, wijntjes en meer!
              </p>
              
            </Container>
          </Jumbotron>
          <img src={affiche} className="affiche-activiteit" alt="affiche"/>
        </div>
        <div id="barbaptist" className="activiteitpagina-text">
          <img src={affiche} className="affiche-activiteit-mobile" alt="affiche"/>
          <div>
            <h1>Vrijdag 02/08</h1>
            <br/>
            <h2>Gratis inkom</h2>
            <br/>
            <p>In onze Bar Baptist serveren we u heel het weekend lekkere speciaalbieren, heerlijke wijntjes en meer! <br/>De kleine honger kan gestild worden met een chipske, de iets grotere met ne goeie vettige hamburger!
            <br/><br/>Op vrijdag krijgen we bezoek van enkele bands en dj, namelijk: The Mind, Ave Noenkel, De Schlagerij & DJ Gert V.<br/></p>
            <br/>
            <p>Betalen kan zowel met cash als via bancontact</p>
            <h3>Meer info over de bands en dj's kan je hieronder vinden!</h3>
          </div>
        </div>
        <div className="activiteitpagina-text">
          <h1 ref="vrijdag">Vrijdag 02/08</h1>
          <div className="lineup-artiesten">
            <div className="band">
              <div className="band-img" style={{backgroundImage: groep1Img}}></div>
              <div>
                <h2>{band1.naam}</h2>
                <h3>{band1.uur}</h3>
                <p>{band1.tekst}</p>
                <div className="band-links">
                  {band1.website && (
                    <a href={band1.website} target="_blank" rel="noopener noreferrer"><i className="fas fa-laptop-code"></i></a>
                  )}
                  {band1.facebook && (
                    <a href={band1.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                  )}
                  {band1.instagram && (
                    <a href={band1.instagram} target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
                  )}
                  {band1.youtube && (
                    <a href={band1.youtube} target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube"></i></a>
                  )}
                </div>
              </div>
            </div>
            <div className="band">
              <div className="band-img" style={{backgroundImage: groep2Img}}></div>
              <div>
                <h2>{band2.naam}</h2>
                <h3>{band2.uur}</h3>
                <p>{band2.tekst}</p>
                <div className="band-links">
                  {band2.website && (
                    <a href={band2.website} target="_blank" rel="noopener noreferrer"><i className="fas fa-laptop-code"></i></a>
                  )}
                  {band2.facebook && (
                    <a href={band2.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                  )}
                  {band2.instagram && (
                    <a href={band2.instagram} target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
                  )}
                  {band2.youtube && (
                    <a href={band2.youtube} target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube"></i></a>
                  )}
                </div>
              </div>
            </div>
            <div className="band">
              <div className="band-img" style={{backgroundImage: groep3Img}}></div>
              <div>
                <h2>{band3.naam}</h2>
                <h3>{band3.uur}</h3>
                <p>{band3.tekst}</p>
                <div className="band-links">
                  {band3.website && (
                    <a href={band3.website} target="_blank" rel="noopener noreferrer"><i className="fas fa-laptop-code"></i></a>
                  )}
                  {band3.facebook && (
                    <a href={band3.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                  )}
                  {band3.instagram && (
                    <a href={band3.instagram} target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
                  )}
                  {band3.youtube && (
                    <a href={band3.youtube} target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube"></i></a>
                  )}
                </div>
              </div>
            </div>
            <div className="band">
              <div className="band-img" style={{backgroundImage: groep4Img}}></div>
              <div>
                <h2>{band4.naam}</h2>
                <h3>{band4.uur}</h3>
                <p>{band4.tekst}</p>
                <div className="band-links">
                  {band4.website && (
                    <a href={band4.website} target="_blank" rel="noopener noreferrer"><i className="fas fa-laptop-code"></i></a>
                  )}
                  {band4.facebook && (
                    <a href={band4.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                  )}
                  {band4.instagram && (
                    <a href={band4.instagram} target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
                  )}
                  {band4.youtube && (
                    <a href={band4.youtube} target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube"></i></a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default Barbaptist;
