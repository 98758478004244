import React from 'react';
import './style.css';
import affiche from '../info/overzicht.png';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Header from '../header/Header';

import restaurant from "../info/baptistfeesten 2023_assets-09.png"

class Chezbaptist extends React.Component {

  componentDidMount() {
      window.scrollTo(0, 0);
    }

  render() {
    return (
    <div className="page">
      <Header id="chezbaptist"/>
      <div className="activiteitpagina">
        <div className="activiteitpagina-header">
          <Jumbotron fluid>
            <Container>
              <h1>Chez Baptist</h1>
              <p>
                Mosselrestaurant met zitgelegenheid binnen
              </p>
              
            </Container>
          </Jumbotron>
          <img src={affiche} className="affiche-activiteit" alt="affiche"/>
        </div>
        <div id="chezbaptist" className="activiteitpagina-text">
          <img src={affiche} className="affiche-activiteit-mobile" alt="affiche"/>
          <div>
            <h1>Zaterdag 03/08 & Zondag 04/08</h1>
            <h2>ZAT & ZON: 12u00 - 21u00</h2>
            <h3>Zonder reservaties</h3>
            <br/>
            <p>Bij Chez Baptist moet je zijn als je houdt van een goede portie verse mosselen, natuur of met look! <br/><br/>Voor de mensen die niet zo zot zijn van mosselen hebben we natuurlijk ook nog enkele lekkere alternatieven zoals stoofvlees, koude schotel, visschotel. <br/>Kindermenu's worden voorzien voor de kleinsten van het gezelschap. Zij kunnen kiezen uit een kinderportie mosselen, curryworst of chick fingers met frietjes. <br/><br/>Heb je daarna nog een zoet goestingske? <br/>Dan vullen we je buikje graag met nog wat dessertjes: rijstpap, chocomousse of een ijsje. <br/><br/>En ook in ons restaurant kan je genieten van onze wel uitgekozen speciaalbieren en wijnen.</p>
            <p>Betalen kan zowel met cash als via bancontact.</p>
          <br/>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default Chezbaptist;
