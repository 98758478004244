import React from 'react';
import './style.css';
import affiche from './springkastelen.png';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Header from '../header/Header';

import springkasteel from "../info/baptistfeesten 2023_assets-17.png"

class Springkastelen extends React.Component {

  componentDidMount() {
      window.scrollTo(0, 0);
    }

  render() {
    return (
    <div className="page">
      <Header id="springkastelen"/>
      <div className="activiteitpagina">
        <div className="activiteitpagina-header">
          <Jumbotron fluid>
            <Container>
              <h1>Springkastelen<br/>festival</h1>
              <p>
                Met grime, volksspelen en gezelschapsspelen!
              </p>
              
            </Container>
          </Jumbotron>
          <img src={affiche} className="affiche-activiteit" alt="affiche"/>
        </div>
        <div id="springkastelen" className="activiteitpagina-text">
          <img src={affiche} className="affiche-activiteit-mobile" alt="affiche"/>
          <div>
            <h1>Zaterdag 03/08</h1>
            <h2>Van 11u00 - 17u00</h2>
            <h3>Prijs: 6 euro inkom (zonder voorinschrijving)</h3>
            <br/>
            <p>Laat je kinderen een topnamiddag beleven op ons springkastelenfestival. <br/>Er zullen een 5-tal springkastelen opgesteld staan waarop zowel jonge als oudere kinderen zich ten volle kunnen uitleven. Daarnaast zullen er ook randactiviteiten zijn zoals o.a. grime, volksspelen en levensgrote gezelschapsspellen. En terwijl de kinderen zich amuseren, kunnen de ouders op hun gemak iets drinken op ons terras.</p>
            <br/><br/>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default Springkastelen;
