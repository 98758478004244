import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch } from 'react-router-dom';

import * as ROUTES from './constants/routes'

import Home from './components/home/Home';
import Barbaptist from './components/barbaptist/Barbaptist';
import Springkastelen from './components/springkastelen/Springkastelen';
import Darts from './components/darts/Darts';
import Chezbaptist from './components/chezbaptist/Chezbaptist';
import Menu from './components/chezbaptist/Menu';
import Tornooi from './components/tornooi/Tornooi';
import Corona from './components/corona/Corona';
import Order from './components/orders/Order';

class App extends React.Component {
    render() {
    return (
      <div className="App">
        <Switch>
          <Route path={ROUTES.HOME} component={Home} exact/>
          <Route path={ROUTES.BARBAPTIST} component={Barbaptist} />
          <Route path={ROUTES.SPRINGKASTELEN} component={Springkastelen} />
          <Route path={ROUTES.DARTS} component={Darts} />
          <Route path={ROUTES.RESTAURANT} component={Chezbaptist} exact/>
          <Route path={ROUTES.MENU} component={Menu} exact/>
          <Route path={ROUTES.TORNOOI} component={Tornooi} />
          <Route path={ROUTES.CORONA} component={Corona} />
          <Route exact path={ROUTES.ORDERS_DETAILS} component={Order} />
        </Switch>
      </div>
    )
  }
}

export default App;
