import React from 'react';
import './style.css';
import affiche from '../info/overzicht.png';
import axios from 'axios';
import { withFirebase } from '../Firebase';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Header from '../header/Header';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

class Darts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ploegnaam: "",
      contactpersoon: "",
      email: "",
      gsm: "",
      opmerking: "",
      ingediend: false,
      betalingLaden: false
    }
  }

  componentDidMount() {
      window.scrollTo(0, 0);
    }

  onChangeText = event => {
   this.setState({ [event.target.name]: event.target.value });
  };

  indienen() {
    if(this.state.ploegnaam === "" || this.state.contactpersoon === "" || this.state.email === "" || this.state.gsm === "") {
      alert("Gelieve alle velden in te vullen")
    } else {
      this.setState({
        betalingLaden: true
      })

      var key = this.props.firebase.dartsInschrijvingen().push().getKey()

      this.props.firebase.dartsInschrijvingenItem(key).update({
        ploegnaam: this.state.ploegnaam,
        contactpersoon: this.state.contactpersoon,
        email: this.state.email,
        gsm: this.state.gsm,
        opmerking: this.state.opmerking,
        datum: this.props.firebase.serverValue.TIMESTAMP,
        status: "Nog te betalen",
        emailGekregen: false
      })
      .then((async()=> {
        try {
 
          const descriptionBetaling = "Darts Baptistfeesten 2024- "+ this.state.ploegnaam + "("+this.state.contactpersoon+")"
          const bedrag = "20.00"
          const redirectUrl = "http://www.baptistfeesten.be/inschrijving_controle"
          const response = await axios.get('https://us-central1-baptistfeesten.cloudfunctions.net/molliePaymentBaptistfeesten', {params: {bedrag: bedrag, description: descriptionBetaling, redirectUrl: redirectUrl, key: key, email: this.state.email}})
          window.location.replace(response.data)
      } catch(error){
        console.error("error" + error);
      }
    }))

      
    }
  }

  render() {
    var {ingediend} = this.state;

    if(this.state.ploegnaam === "" || this.state.contactpersoon === "" || this.state.email === "" || this.state.gsm === "") {
      var disabled = true
    } else {
      var disabled = false
    }
    return (
    <div className="page">
      <Header id="sjotterkas"/>
      <div className="activiteitpagina">
        <div className="activiteitpagina-header">
          <Jumbotron fluid>
            <Container>
              <h1>Recreatief dartstornooi</h1>
              <p>
                180!!!
              </p>
            </Container>
          </Jumbotron>
          <img src={affiche} className="affiche-activiteit" alt="affiche"/>
        </div>
        <div id="sjotterkas" className="activiteitpagina-text">
          <img src={affiche} className="affiche-activiteit-mobile" alt="affiche"/>
          <div>
            <h1>Zaterdag 03/08</h1>
            <h2>Van 17u00 tot 23u00</h2>
            <h3>Prijs: 20 euro/ploeg (2 personen)</h3>
            <br/>
            <p>Met een <strong>afterparty</strong> vanaf 23u!!!</p>
            <br/>
            <h3>Extra info:</h3>
            <ul>
              <li>Outdoor onder de tent</li>
              <li>Spelformule = best out of 3 (aftellen van 501 naar 0, dubbel uitgooien)</li>
              <li>Eliminaties na de eerste poule</li>
              <li>Niet-spelers scheidsen de lopende dartswedstrijden</li>
            </ul>
          </div>
        </div>
        <div className="activiteitpagina-text" id="inschrijfsection">
          <div>
          <h1>Inschrijven Recreatief dartstornooi 2024</h1>
          <br/>
          {!ingediend ? (
            <Form>
              <Form.Group className="mb-3" controlId="formGridPloegnaam">
                <Form.Label style={{display: "flex"}}>Ploegnaam<div style={{color: "red"}}>*</div></Form.Label>
                <Form.Control placeholder="Ploegnaam" name="ploegnaam" value={this.state.ploegnaam} onChange={this.onChangeText}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGridPloegnaam">
                <Form.Label style={{display: "flex"}}>Naam contactpersoon<div style={{color: "red"}}>*</div></Form.Label>
                <Form.Control placeholder="Naam" name="contactpersoon" value={this.state.contactpersoon} onChange={this.onChangeText}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{display: "flex"}}>Email<div style={{color: "red"}}>*</div></Form.Label>
                <Form.Control type="email" placeholder="naam@voorbeeld.be" name="email" value={this.state.email} onChange={this.onChangeText}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{display: "flex"}}>GSM nummer<div style={{color: "red"}}>*</div></Form.Label>
                <Form.Control placeholder="0479/99.99.99" name="gsm" value={this.state.gsm} onChange={this.onChangeText}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label style={{display: "flex"}}>Opmerkingen/suggesties</Form.Label>
                <Form.Control as="textarea" rows={3} name="opmerking" value={this.state.opmerking} onChange={this.onChangeText}/>
              </Form.Group>
              <p>Na de inschrijving wordt je doorverwezen naar de betaalpagina.</p>
              <Button variant="primary" disabled={disabled} onClick={() => this.indienen()}>
                Inschrijven
              </Button>
              {this.state.betalingLaden && (
                <div id="betaling-aanvragen-display">
                  <div className="betaling-aanvragen-text">
                    <h1>We hebben je gegevens goed ontvangen!</h1>
                    <br/>
                    <h3>Je wordt zodadelijk doorverwezen naar de betaalpagina</h3>
                  </div>
                  <div className="betaling-aanvragen-bg"></div>
                </div>
              )}
            </Form>
          ) : (
            <div>
              <h3>Je inschrijving werd doorgestuurd!</h3>
              <h5>Binnenkort krijg je van ons nog een bevestiging van deze inschrijving!</h5>
              <h6>(Bekijk zeker je spam folder!)</h6>
            </div>
          )}
        </div>
        </div>
      </div>
    </div>
    )
  }
}

export default withFirebase(Darts);
