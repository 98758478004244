import React from 'react';
import bg from './bg.png';
import logo from '../../logo.svg';
import './style.css';
import Countdown from '../../Countdown';

import Fade from 'react-reveal/Fade';

import Info from '../info/Info';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tafelreserveren: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  openReserveren() {
    this.setState({
      tafelreserveren: true,
    });
  }

  closeReserveren() {
    window.location.reload(false);
  }

  render() {
    var datumEvent = new Date(2024,7,2,18,0)
    return (
          <div>
            <Fade>
              <div data-anchor="Home" id="homepage" style={{backgroundImage: "url("+bg+")"}}>
                <div className="intro-logo-jaar">
                  <h1 id="naam-evenement">BAPTISTFEESTEN</h1>
                  <h1 id="jaar">2024</h1>
                  <h5 id="datum">VRIJ 02/08 T.E.M. ZON 04/08</h5>
                </div>
                <Countdown date={datumEvent}/>
              </div>
            </Fade>

            
            <Info />

          </div>
        )
      }
    }


export default Home;
