import React from 'react';
import './style.css';

import prijslijstBuiten from './PrijslijstBuiten.png'
import speciaalBier1 from './SpeciaalBier1.png'
import speciaalBier2 from './SpeciaalBier2.png'

class Menu extends React.Component {

  componentDidMount() {
      window.scrollTo(0, 0);
    }

  render() {
    return (
    <div className="page" id="baptistmenu">
      <br/>
      <h1 style={{fontSize: "2em"}}>Op het menu</h1>

      <img className="menu-foto" src={prijslijstBuiten} alt="prijsBuiten"/>

      <img className="menu-foto" src={speciaalBier1} alt="speciaalBier1"/>

      <img className="menu-foto" src={speciaalBier2} alt="speciaalBier2"/>
    </div>
    )
  }
}

export default Menu;
