import React from 'react';
import './style.css';

import Header from '../header/Header';

class Corona extends React.Component {

  render() {
    // eslint-disable-next-line
    return (
    <div className="page" >
      <Header id="barbaptist"/>
        <div id="corona">
          <br/><br/>
          <h1>Baptist & Corona</h1>
          <div>
          <br/><br/>
            <p>Meer info binnenkort beschikbaar</p>
          </div>
        </div>
    </div>
    )
  }
}

export default Corona;
