import React from 'react';
import './style.css';
import { LinkContainer  } from "react-router-bootstrap";
import Logo from '../../logo.svg';
import * as ROUTES from "../../constants/routes"

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

class Header extends React.Component {

  render() {
    return (
      <Navbar expand="xl" id="navbar-activiteiten">
          <LinkContainer to={ROUTES.HOME}><h1><div style={{color: "black"}}>BAPTIST</div>FEESTEN</h1></LinkContainer>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <LinkContainer to={ROUTES.HOME}><p className="header-link">Home</p></LinkContainer>
              <LinkContainer to={ROUTES.BARBAPTIST}><p className="header-link">Bar Baptist</p></LinkContainer>
              <LinkContainer to={ROUTES.SPRINGKASTELEN}><p className="header-link">Springkastelen</p></LinkContainer>
              <LinkContainer to={ROUTES.DARTS}><p className="header-link">Darts</p></LinkContainer>
              <LinkContainer to={ROUTES.RESTAURANT}><p className="header-link">Chez Baptist</p></LinkContainer>
              <LinkContainer to={ROUTES.TORNOOI}><p className="header-link">Volleybaltornooi</p></LinkContainer>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Navbar>
    )
  }
}

export default Header;
