import React from 'react';
import './style.css';
import affiche from './overzicht.png';
import { LinkContainer  } from "react-router-bootstrap";

import * as ROUTES from "../../constants/routes"

import bier from "./baptistfeesten 2023_assets-12.png"
import springkasteel from "./baptistfeesten 2023_assets-17.png"
import restaurant from "./baptistfeesten 2023_assets-09.png"
import volleybal from "./baptistfeesten 2023_assets-04.png"
import darts from "./baptistfeesten 2023_assets-15.png"

class Info extends React.Component {

  render() {
    return (
      <div className="section">
        <div>
           <h1>Feest op en rond het kerkplein van Schriek!</h1>
             <div id="section-info-grid">
               <img src={affiche} alt="affiche" className="affiche"></img>
               <div className="text-activiteiten-grid">
                 <p>Volleybalclub Schriek organiseert tijdens het eerste volledige weekend van augustus naar goede traditie de Baptistfeesten op en rond het kerkplein van Schriek. <br/><br/>Tijdens dit feestweekend kan er naar hartenlust gegeten, gedronken, gespeeld, gedanst en gezongen worden!</p>
                <h4>Meer info:</h4>

                   <div className="lijst-activiteiten">
                     <LinkContainer to={ROUTES.BARBAPTIST}><div className="activiteitKnop" id="knopBarBaptist"><img className="icon-info" src={bier} alt="bier"/>Bar Baptist</div></LinkContainer>
                     <LinkContainer to={ROUTES.SPRINGKASTELEN}><div className="activiteitKnop" id="knopSpringkastelen"><img className="icon-info" src={springkasteel} alt="springkasteel"/>Springkastelenfestival</div></LinkContainer>
                     <LinkContainer to={ROUTES.DARTS}><div className="activiteitKnop" id="knopSjotterkas"><img className="icon-info" src={darts} alt="darts"/>Recreatief dartstornooi</div></LinkContainer>
                     <LinkContainer to={ROUTES.RESTAURANT}><div className="activiteitKnop" id="knopRestaurant"><img className="icon-info" src={restaurant} alt="restaurant"/>Mosselrestaurant <br/>Chez Baptist</div></LinkContainer>
                     <LinkContainer to={ROUTES.TORNOOI}><div className="activiteitKnop" id="knopTornooi"><img className="icon-info" src={volleybal} alt="volleybal"/>Recreatief <br/>volleybaltornooi</div></LinkContainer>
                     <div onClick={() => window.open("https://www.facebook.com/events/1442955856165983")}><div className="activiteitKnop" id="knopInitiatie"><i className="fa-brands fa-facebook"></i>Onze facebook</div></div>
                   </div>
               </div>
              </div>
           </div>
     </div>
    )
  }
}

export default Info;
